import React from "react"
import * as R from "ramda"
import { graphql, Link } from "gatsby"
import GetMicroCopy from "../shared/getMicroCopy"
import { useMicroCopyFi } from "../hooks/microCopy"
import { useMicroCopySv } from "../hooks/microCopy/sv"
import { useMicroCopyEn } from "../hooks/microCopy/en"
import { BgImage } from "gbimage-bridge"
import LayoutController from "../components/layoutController"

import { GatsbyImage } from "gatsby-plugin-image"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Seo from "../components/seo"

import BasicPageSidebar from "../components/basicPageSidebar"
import BasicPageReferences from "../components/basicPageReferences"
import InternalPageReleaseList from "../components/internalPageReleaseList"

import * as styles from "./basicSubPage.module.scss"

const BasicSubPage = ({ data, pathContext }) => {
  const pageContent = R.path(["contentfulContent"], data)
  const parentContent = R.path(["parentContent"], data)
  const language =  R.path(["node_locale"], parentContent)
  const microCopyTexts = language === 'sv' ? useMicroCopySv : language === 'en' ? useMicroCopyEn : useMicroCopyFi
  // UI texts
  const labelHomePage = GetMicroCopy(microCopyTexts, "yleinenEtusivu")
  // end UI texts

  const mainImage = R.path(["mainImage", "gatsbyImageData"], parentContent)
  const title = R.path(["title"], parentContent)
  const subPageTitle = R.path(["title"], pageContent)
  const slug = R.path(["slug"], parentContent)

  const folder = R.path(["folder"], parentContent)
  const folderSlug = R.path(["folderSlug"], parentContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], parentContent)

  const {
    shape_lightGreen,
    shape_darkGreen,
    shape_yellow,
    shape_pink,
    shape_lightBlue,
  } = data

  const shapeLightGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightGreen
  )
  const shapeDarkGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_darkGreen
  )
  const shapeYellow = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_yellow
  )
  const shapePink = R.path(["childImageSharp", "gatsbyImageData"], shape_pink)
  const shapeLightBlue = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightBlue
  )

  // Youtube embedly help
  const regexEmbedlyText = new RegExp("Embedded content: (.*)", "g")
  const regexYouTube =
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/g
  let textContent = R.path(["contentText", "contentText"], parentContent)
  if (textContent) {
    textContent = textContent
      .replace(regexEmbedlyText, "")
      .replace(regexYouTube, "https://youtu.be/$1?rel=0")
  }

  const contentTextShort = R.path(["contentTextShort"], pageContent)

  const mobileSizeDetected = useMediaQuery("(max-width:700px)")

  // Gatsby background images seem to jump around during rendering. Static backgroundsize helps.
  // The following stuff helps to take care of this problem and defines two sizes for mobile/laptop.
  let shapeColor = ""
  let shapeSize = ""
  let shapeMinHeight = ""
  let titleMarginBottom = ""

  shapeMinHeight = mobileSizeDetected ? "41px" : "190px"
  titleMarginBottom = mobileSizeDetected ? "-25px" : "-5px"

  const color = R.path(["color"], parentContent)

  if (color === "Vihreä") {
    shapeColor = shapeLightGreen
    shapeSize = mobileSizeDetected ? "60px 41px" : "190px 129px"
  }
  if (color === "Tummanvihreä") {
    shapeColor = shapeDarkGreen
    shapeSize = mobileSizeDetected ? "62px 41px" : "190px 129px"
  }
  if (color === "Keltainen") {
    shapeColor = shapeYellow
    shapeSize = mobileSizeDetected ? "62px 41px" : "193px 129px"
  }
  if (color === "Sininen") {
    shapeColor = shapeLightBlue
    shapeSize = mobileSizeDetected ? "62px 41px" : "191px 128px"
  }
  if (color === "Pinkki") {
    shapeColor = shapePink
    shapeSize = mobileSizeDetected ? "62px 41px" : "203px 135px"
  }

  return (
    <LayoutController language={language}>
    <Box>
      <Seo
        seoTitle={title}
        seoDesc={contentTextShort}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "35px",
          marginBottom: "100px",
          padding: "0 20px",
        }}
      >
        <Box style={{ maxWidth: "1200px", width: "100%" }}>
          <Box>
            <Grid container>
              <Grid item sm={12} lg={6} className={styles.titleContainer}>
                <div
                  className={styles.bgimageContainer}
                  style={{ marginLeft: "-35px" }}
                >
                  <BgImage
                    image={shapeColor}
                    style={{
                      minHeight: shapeMinHeight,
                      backgroundSize: shapeSize,
                      backgroundPositionX: "0px",
                    }}
                  >
                    <div
                      className={styles.header}
                      style={{ marginBottom: titleMarginBottom }}
                    >
                      <h1>{title}</h1>
                    </div>
                  </BgImage>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box className={styles.mainImageContainer}>
            <GatsbyImage image={mainImage} style={{ maxHeight: "656px" }} />
            <span id={subPageTitle}></span>
          </Box>
          <Grid container direction="row">
            <Grid item sm={12} md={4}>
              <p className="categoryText">
                <Link to="/">{labelHomePage}</Link> ·{" "}
                <Link to={`/${folderSlug}`}>{folder}</Link> ·{" "}
                <Link to={`/${folderSlug}/${slug}`}>{title}</Link>
              </p>
              <BasicPageSidebar pageContent={parentContent} language={language} />
            </Grid>
            <Grid item sm={false} md={1}></Grid>
            <Grid item sm={12} md={7}>
              <h2 className="basicSubPageTitle">{subPageTitle}</h2>
              <BasicPageReferences pageContent={pageContent} />
            </Grid>
          </Grid>

          {subPageTitle == "Tiedotteet" && (
            <InternalPageReleaseList data={data} pathContext={pathContext} />
          )}
        </Box>
      </Box>
    </Box>
    </LayoutController>
  )
}

export default BasicSubPage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query basicSubPageQuery($uniqueEntryTitle: String, $parentSlug: String) {
    parentContent: contentfulPerussivu(slug: { eq: $parentSlug }) {
      id
      slug
      title
      folder
      folderSlug
      node_locale
      color
      mainImage {
        file {
          url
        }
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      contentTextShort
      contentText {
        contentText
      }
      subpages {
        node_locale
        ... on Node {
          ... on ContentfulPerusalasivu {
            __typename
            id
            slug
            title
            uniqueEntryTitle
          }
        }
      }
    }
    contentfulContent: contentfulPerusalasivu(
      uniqueEntryTitle: { eq: $uniqueEntryTitle }
    ) {
      id
      slug
      title
      node_locale
      contentTextShort
      contentArea {
        ... on Node {
          ... on ContentfulHaitarielementti {
            __typename
            id
            alaosiot {
              title
              childContentfulHaitarialaosioContentTextTextNode {
                contentText
              }
            }
          }
          ... on ContentfulLinkkilista {
            __typename
            id
            name
            links {
              ... on Node {
                ... on ContentfulUlkoinenLinkki {
                  __typename
                  id
                  url
                  urlText
                }
              }
            }
          }
          ... on ContentfulPerusalasivunTekstikappale {
            __typename
            id
            title
            

            richText {
              raw
              references {
                ... on Node {
                  ... on ContentfulAsset {
                    id
                    contentful_id
                    __typename
                    title
                    description
                    gatsbyImageData(width: 800)
                    file {
                      url
                      fileName
                    }
                  }
                }
                ... on Node {
                  ... on ContentfulHenkilo {
                    node_locale
                    contentful_id
                    id
                    __typename
                    name
                    contentText {
                      contentText
                    }
                    image {
                      gatsbyImageData(width: 300)
                    }
                  }
                }
                ... on Node {
                  ... on ContentfulNosto {
                    id
                    node_locale
                    contentful_id
                    __typename
                    title
                    contentText {
                      contentText
                    }
                    image {
                      gatsbyImageData(width: 500)
                    }
                    buttonText
                    buttonLink
                    backgroundColor
                    reverseImage
                  }
                }
                ... on Node {
                  ... on ContentfulPainike {
                    id
                    node_locale
                    contentful_id
                    __typename
                    ctaText
                    ctaTarget
                    ctaColor
                  }
                }
                ... on Node {
                  ... on ContentfulNostoruudukko {
                    contentful_id
                    __typename
                    id
                    node_locale
                    teasers {
                      ... on Node {
                        ... on ContentfulNosto {
                          id
                          node_locale
                          contentful_id
                          __typename
                          title
                          contentText {
                            contentText
                          }
                          image {
                            gatsbyImageData(width: 500)
                          }
                          buttonText
                          buttonLink
                          backgroundColor
                        }
                      }
                    }
                  }
                }
              }
            }



            contentText {
              contentText
            }
          }
          ... on ContentfulPerusalasivunosto {
            __typename
            id
            color
            contentText {
              contentText
            }
          }
        }
      }
    }
    shape_lightGreen: file(relativePath: { eq: "shape_lightGreen.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_darkGreen: file(relativePath: { eq: "shape_darkGreen.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_yellow: file(relativePath: { eq: "shape_yellow.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_pink: file(relativePath: { eq: "shape_pink.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    shape_lightBlue: file(relativePath: { eq: "shape_lightBlue.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
    allInternalReleases(filter: { id: { ne: "dummy" } }) {
      nodes {
        releases {
          alternative_id
          title
          leadtext
          keywords
          body
          published
          version
          language
          url
          type
          leadTextOrBodyStrip
          mainImage {
            thumbnail_4_3
          }
        }
      }
      totalCount
      pageInfo {
        perPage
        itemCount
        pageCount
      }
    }
  }
`
