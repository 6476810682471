import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import * as styles from "./internalPageReleaseList.module.scss"

const slugify = require("slugify")

const InternalPageReleaseList = ({ data, pathContext }) => {
  const uniqueEntryTitle = R.path(["uniqueEntryTitle"], pathContext)
  const currentUrl = typeof window !== "undefined" ? window.location.href : ""
  const pageCount = currentUrl.includes(`/#${uniqueEntryTitle}/`)
    ? currentUrl.substring(currentUrl.lastIndexOf("/") + 1)
    : 1
  const action = "Lue lisää"
  const releases = R.path(["allInternalReleases", "nodes", 0, "releases"], data)

  // Sort DESC by alternative_id
  const customSort = R.sortWith([R.descend(R.prop("alternative_id"))])
  const sortedTeleases = customSort(releases)

  // Pagination
  const perPage = 9 // 9 releases per page
  const totalCount = sortedTeleases.length //R.path(['allInternalReleases', 'totalCount'], data);
  const start = (pageCount - 1) * perPage
  const previousPageCount = pageCount > 1 ? parseInt(pageCount) - 1 : 1
  const previousPageURL =
    pageCount > 1 ? `#${uniqueEntryTitle}/${previousPageCount}` : ""
  const nextPageCount =
    pageCount * perPage < totalCount ? parseInt(pageCount) + 1 : pageCount
  const nextPageURL =
    nextPageCount > pageCount ? `#${uniqueEntryTitle}/${nextPageCount}` : ""
  const end =
    pageCount * perPage < totalCount ? pageCount * perPage : totalCount
  const subSortedReleases = sortedTeleases.slice(start, end)

  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        {subSortedReleases.map(item => (
          <Grid
            item
            sm={12}
            md={6}
            lg={4}
            style={{ width: "100%", marginBottom: "20px" }}
            key={R.path(["alternative_id"], item)}
          >
            <article className={styles.pressReleaseCard}>
              {typeof R.path(["mainImage", "thumbnail_4_3"], item) ===
                "string" &&
                R.path(["mainImage", "thumbnail_4_3"], item) && (
                  <figure>
                    <img
                      src={R.path(["mainImage", "thumbnail_4_3"], item)}
                      alt=""
                    />
                  </figure>
                )}
              <h3>{R.path(["title"], item)}</h3>

              <p>{R.path(["leadtext"], item)}</p>

              <Link
                to={`/release/${slugify(R.path(["title"], item), {
                  remove: /[*+~.()/'"!:@]/g,
                  lower: true,
                })}`}
              >
                <Button variant="contained" color="primary">
                  {action}
                </Button>
              </Link>
            </article>
          </Grid>
        ))}
      </Grid>

      <Grid item sm={12} style={{ textAlign: "center" }}>
        {previousPageURL && (
          <Link
            to={previousPageURL}
            style={{ width: "74px", marginRight: "20px", color: "#000" }}
          >
            <Button variant="contained" color="primary">
              Takaisin
            </Button>
          </Link>
        )}
        {nextPageURL && (
          <Link
            to={`${nextPageURL}`}
            style={{ width: "74px", marginLeft: "20px", color: "#000" }}
          >
            <Button variant="contained" color="primary">
              Seuraava
            </Button>
          </Link>
        )}
      </Grid>
    </Box>
  )
}

export default InternalPageReleaseList
